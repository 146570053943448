export default ({ instance }) => {
	return {
		consultarCertificados(grupoOperacional) {
			return instance({
				method: "get",
				url: "certificado/certificados/" + grupoOperacional,
			});
		},

		consultarGrupoPesquisaDje(grupoOperacional) {
			return instance({
				method: "get",
				url: "certificado/grupo-pesquisa/" + grupoOperacional,
			});
		},

		consultarIdClienteDje(grupoPesquisa) {
			return instance({
				method: "get",
				url: "certificado/consultar-id/" + grupoPesquisa,
			});
		},

		consultarConfiguracoesCertificado(grupoOperacional, nomeCertificado) {
			const parametros = new URLSearchParams({
				grupoOperacional: grupoOperacional,
				nomeCertificado: nomeCertificado,
			}).toString();

			return instance({
				method: "get",
				url: "certificado/configuracoes-certificado?" + parametros,
			});
		},

		alterarConfiguracaoCertificado(data) {
			return instance({
				method: "post",
				url: "certificado/alterar-configuracao-certificado",
				data,
			});
		},

		notificaCadastroCertificado(data) {
			return instance({
				method: "post",
				url: "certificado/email-cadastro-certificado",
				data,
			});
		},

		consultarRepresentadosPorGrupoOperacional(grupoOperacional) {
			return instance({
				method: "get",
				url:
					"comunicacaojudicial/representados-por-grupo/" +
					grupoOperacional,
			});
		},

		consultarTodosTribunaisPorGrupoOperacional(grupoOperacional) {
			return instance({
				method: "get",
				url:
					"comunicacaojudicial/tribunais-por-grupo/" +
					grupoOperacional,
			});
		},

		consultarProcessosPorFiltro(data) {
			return instance({
				method: "post",
				url: "comunicacaojudicial/processos-por-filtro",
				data,
				data,
			});
		},
		consultarProcessosPorCodigo(data) {
			return instance({
				method: "post",
				url: "comunicacaojudicial/processos-por-codigo",
				data,
			});
		},
		consultarHistoricoNumeroProcesso(data) {
			return instance({
				method: "post",
				url: "comunicacaojudicial/processo-por-npu",
				data,
			});
		},
		comunicacaoJudExportarProcessosExcel(data) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "comunicacaojudicial/exportar-processos-excel",
				data,
				data,
			});
		},
		comunicacaoJudExportarProcessosJson(data) {
			return instance({
				method: "post",
				url: "comunicacaojudicial/exportar-processos-json",
				data,
				data,
			});
		},
		comunicacaoJudExportarProcessosXml(data) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "comunicacaojudicial/exportar-processos-xml",
				data,
				data,
			});
		},
		comunicacaoJudExportarProcessosDocx(data) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "comunicacaojudicial/exportar-processos-docx",
				data,
			});
		},
	};
};
